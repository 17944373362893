/**
 * @module Tooltip
 * @description Renders a Tippy tooltip given a legal disclosure hash
 */
import { useContext, useEffect, useState } from 'react';
import { WordPressContext } from '@/providers/wordpress';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

/**
 * @function Component
 * @description
 */
const Component = ( props ) => {
  const { disclosures } = useContext( WordPressContext );
  const [ content, setContent ] = useState( '' );

  useEffect( () => {
    if ( ! disclosures?.length ) return;

    const legal = disclosures.filter( ( d ) => d.hash === props.disclosure )[ 0 ];

    setContent( legal?.full ?? '' );
  }, [ props.disclosure, disclosures ] );

  if ( ! content ) return null;

  return (
    <Tippy
      key="tooltip-1"
      content={ <p key="paragraph-1" className="tooltip-content--legal">{ content }</p> }
      placement={ props.placement }
      arrow={ true }
      trigger="mouseenter focus click"
      theme="light"
      className="tooltip-icon"
      allowHTML={ true }
      { ...props }
    >
      <button className="tooltip-btn" role="button">
        <span className="icon icon-info-outline" />
        <span className="screen-reader-text">Tooltip Icon</span>
      </button>
    </Tippy>
  );
};

Component.defaultProps = {
  disclosure: '',
  placement: 'bottom'
};

export default Component;
